import React from 'react'
import PhysicianLandingPage from 'containers/physician-landing-page'

const RosefinchLandingPage = () => (
  <PhysicianLandingPage
    physician="Dr. Lee"
    institution="Rosefinch Health"
    referralCode="ROSEFINCH"
    physicianURL="https://www.rosefinchhealth.com/"
  />
)

export default RosefinchLandingPage
